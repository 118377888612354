import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <SocialLinks styleClass="footer-links"></SocialLinks>
        <h4>
          Copyright&copy;{new Date().getFullYear()}
          <h5 className="footer-h5-fix">
            Estacio.<span>dev</span> all rights reserved
          </h5>
        </h4>
      </div>
    </footer>
  )
}

export default Footer
